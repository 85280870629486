<template>
    <div>
        <div>
            操作室名称：{{ detail.roomName }}
        </div>
        <div>
            打卡人员：{{ detail.userName }}
        </div>
        <div>
            操作内容：{{ detail.workMessage }}
        </div>
        <div>
            操作开始时间：{{ detail.workTime }}
        </div>
        <div v-if="detail.finishTime !== '-1'">
            操作结束时间：{{ detail.finishTime }}
        </div>
        <div>
            <img style="width: 100%;" :src="detail.clockPhoto" alt="#"/>
        </div>
    </div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";
import VXETable from "vxe-table";
import {Decrypt} from "@/utils/secret";

export default {
    name: "",
    inject: ['reload'],
    computed: {
        ...mapGetters(['GET_BDST']),
        ...mapGetters(['GET_USER'])
    },
    data() {
        return {
            clock_id: '',
            detail: []
        }
    },
    created() {
        if (!this.$route.params.key){
            ElMessage.error('非法跳转!')
        }else {
            this.clock_id = this.$route.params.clock_id
            this.get_deatil()
        }
	    // this.clock_id = 'reoNaZuU'
	    // this.get_deatil()
    },
    methods: {
        get_deatil(){
            this.axios.get('/sysWork/getWorkById', (response) => {
                this.detail = response.obj
	            this.detail.clockPhoto = Decrypt(this.detail.clockPhoto)
            },{
                id: this.clock_id
            })
        }
    }
}
</script>

<style>

</style>